import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUpcomingEvents } from "../../service/eventsapi";
import { Box, Heading, Link } from "@chakra-ui/react";
import { EventCard } from "./EventCard";

export function OtherEvents({ eventId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getUpcomingEvents()
      .then((r) => {
        // console.log(r);
        setEvents(r.data);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [eventId]);

  const otherEvents = eventId
    ? events.filter((e) => e.documentId !== eventId)
    : events ?? [];
  //   const otherEvents = events;

  //   console.log({ otherEvents });

  return (
    <Box py={10}>
      <Heading mb={8} fontSize="xl">
        Other Events You may Like
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns={"repeat(auto-fill,minmax(300px,1fr))"}
        gap={6}
      >
        {otherEvents.length === 0 ? (
          <p>No events at the moment</p>
        ) : (
          otherEvents.map((r) => {
            return (
              <Link
                href={`/events/${r.documentId}`}
                style={{ textDecoration: "none" }}
              >
                <EventCard event={r} />
              </Link>
            );
          })
        )}
      </Box>
    </Box>
  );
}
