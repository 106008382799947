import { baseAPI } from "./api";

// Fetch all upcoming events
export const getUpcomingEvents = async () => {
  try {
    // remove time
    const today = new Date().toISOString().split("T")[0];
    const response = await baseAPI.get(
      `/event-calendars?filters[$or][0][StartDate][$gte]=${today}&filters[$or][1][EndDate][$gte]=${today}&sort[0]=StartDate:asc&populate[Image][fields][1]=url`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching upcoming events:",
      error.response ? error.response.data : error.message
    );
  }
};

// Fetch events on a specific date
export const getEventsOnDate = async (date) => {
  try {
    const response = await baseAPI.get(
      `/event-calendars?filters[StartDate][$eq]=${date}&populate[Image][fields][1]=url`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching events:",
      error.response ? error.response.data : error.message
    );
  }
};

// Create a new event
export const createEvent = async (eventData) => {
  try {
    const response = await baseAPI.post(`/event-calendars`, eventData);
    return response.data;
  } catch (error) {
    console.error(
      "Error creating event:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Fetch all events
export const getAllEvents = async () => {
  try {
    const response = await baseAPI.get(
      `/event-calendars&populate[Image][fields][1]=url`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error getting events:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getEventByID = async (id) => {
  try {
    const response = await baseAPI.get(
      `/event-calendars/${id}/?populate[Image][fields][1]=url`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error getting events:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
