import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import theme from "./chakra.theme";
import Footer from "./Components/footer/Footer";
import About from "./pages/About";
import Blog from "./pages/Blog";
import ContactUs from "./pages/Contact";
import EventDetail from "./pages/EventPage";
import Events from "./pages/Events";
import FullArticle from "./pages/FullArticle";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import "./styles/fonts.css";

const App = () => {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/:eventId" element={<EventDetail />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/blog/:articleSlug" element={<FullArticle />} />
          </Routes>
        </div>
        <Footer />
      </ChakraProvider>
    </Router>
  );
};

export default App;
