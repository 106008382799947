import React from "react";
import Eheader from "../Components/events/Eheader";
import EventDetail from "../Components/events/EventDetail";
import { OtherEvents } from "../Components/events/OtherEvents";
import { useParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";

function EventPage() {
  const { eventId } = useParams();
  return (
    <div>
      <Eheader />
      <EventDetail />
      <Box bg="gray.50">
        <Box maxW="1024px" mx="auto" p={4}>
          <OtherEvents eventId={eventId} />
        </Box>
      </Box>
    </div>
  );
}

export default EventPage;
