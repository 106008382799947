import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const baseAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
    // Toggle to allow v4 format
    // "Strapi-Response-Format": "v4",
  },
});
