import React from "react";
import { Box, Container, Text, Image, Flex } from "@chakra-ui/react";
import Gain1 from "../../images/hand.png";
import Gain2 from "../../images/shake.png";
import Gain3 from "../../images/coins.png";
import Gain4 from "../../images/tablechart.jpeg";

const GainSection = () => {
  return (
    <Container
      maxW="none"
      textAlign="center"
      py={10}
      px={0}
      bgColor={"#F2E6E9"}
      style={{ fontFamily: "Avenir, sans-serif" }}
    >
      <Text fontSize="48" color="#801423" fontWeight="bold" mb={10} px={5}>
        What You Stand to Gain
      </Text>

      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        justify="space-between"
        bg="#F2E6E9"
        px={{ base: 0, md: 200 }}
        pb={10}
      >
        {/* Image 1 */}
        <Box position="relative" mb={{ base: 4, md: 0 }}>
          <Image
            src={Gain3}
            alt="Image 1"
            boxSize="100%"
            objectFit="cover"
            borderRadius="3xl"
            h="17.5rem"
            w="13rem"
          />

          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="11%"
            bg="#F2E6E9"
          />
          <Box
            position="absolute"
            bottom="-5"
            left="50%"
            transform="translateX(-50%)"
            bgGradient="linear(to-b, #800020, #90334D)"
            color="white"
            p={2}
            borderRadius="md"
            minW="150px"
          >
            <Text fontWeight="bold">Insight Driven Capital</Text>
          </Box>
        </Box>

        {/* Image 2 */}
        <Box position="relative" mb={{ base: 4, md: 0 }}>
          <Image
            src={Gain1}
            alt="Image 2"
            boxSize="100%"
            objectFit="cover"
            borderRadius="3xl"
            h="17.5rem"
            w="13rem"
          />

          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="11%"
            bg="#F2E6E9"
          />

          <Box
            position="absolute"
            bottom="-5"
            left="50%"
            transform="translateX(-50%)"
            bgGradient="linear(to-b, #800020, #90334D)"
            color="white"
            p={2}
            borderRadius="md"
            minW="150px"
          >
            <Text fontWeight="bold">Product Development Support</Text>
          </Box>
        </Box>

        {/* Image 3 */}
        <Box position="relative" mb={{ base: 4, md: 0 }}>
          <Image
            src={Gain2}
            alt="Image 3"
            boxSize="100%"
            objectFit="cover"
            borderRadius="3xl"
            h="17.5rem"
            w="13rem"
          />

          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="11%"
            bg="#F2E6E9"
          />

          <Box
            position="absolute"
            bottom="-5"
            left="50%"
            transform="translateX(-50%)"
            bgGradient="linear(to-b, #800020, #90334D)"
            color="white"
            p={2}
            borderRadius="md"
            minW="150px"
          >
            <Text fontWeight="bold">Ecosystem Connections</Text>
          </Box>
        </Box>

        {/* Image 4 */}
        <Box position="relative">
          <Image
            src={Gain4}
            alt="Image 4"
            boxSize="100%"
            objectFit="cover"
            borderRadius="3xl"
            h="17.5rem"
            w="13rem"
          />

          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="11%"
            bg="#F2E6E9"
          />

          <Box
            position="absolute"
            bottom="-5"
            left="50%"
            transform="translateX(-50%)"
            bgGradient="linear(to-b, #800020, #90334D)"
            color="white"
            p={2}
            borderRadius="md"
            minW="150px"
            width="170px"
          >
            <Text fontWeight="bold">
              Talent <br /> Resourcing
            </Text>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default GainSection;
