import React from "react";
import { Box, Flex, Image, Heading } from "@chakra-ui/react";
import Fohat from "../../images/fohat5.png";
import Readyhire from "../../images/final-logo-readyhire.png";
import WorkNigeria from "../../images/logo-work-nigeria_0_0.png";
import Firstiglobal from "../../images/firstiglobal.png";
import Firsti from "../../images/firsti.png";
import WeInspireAfrica from "../../images/conference.png";
import Innovate from "../../images/innovatefoundation.png";
import Spurt from "../../images/Spurt.png";

const CompaniesSection = () => {
  return (
    <Box bg="#white" py={10} style={{ fontFamily: "Avenir, sans-serif" }}>
      <Heading
        as="h2"
        size="2xl"
        textAlign="center"
        color="#801423"
        mb={6}
        style={{ fontFamily: "Avenir, sans-serif" }}
      >
        Our Partners
      </Heading>

      <Flex justify="center" alignItems="center" pb={10} gap={{ base: 1, md: 5 }}>
        {/* <Image
          src={Companies}
          alt="Companies Image"
          maxW="100%"
          objectFit={"cover"}
          loading="lazy"
        /> */}
        <Image
          src={Fohat}
          w={{ base: "5rem", md: "11rem", lg: "15rem" }}
          h={{ base: "1.5rem", md: "4rem" }}
        />
        <Image
          src={Firstiglobal}
          w={{ base: "5rem", md: "11rem", lg: "15rem" }}
          h={{ base: "1.5rem", md: "4rem" }}
        />
        <Image
          src={WorkNigeria}
          w={{ base: "5rem", md: "11rem", lg: "16rem" }}
          h={{ base: "1.5rem", md: "4rem" }}
        />
        <Image
          src={Innovate}
          w={{ base: "5rem", md: "11rem", lg: "15rem" }}
          h={{ base: "1.5rem", md: "5rem" }}
        />
        <Image
          src={WeInspireAfrica}
          w={{ base: "5rem", md: "11rem", lg: "15rem" }}
          h={{ base: "1.5rem", md: "4.5rem" }}
        />
      </Flex>
      <Flex justify="center" pb={10}>
        <Image
          src={Readyhire}
          w={{ base: "4rem", md: "10rem" }}
          h={{ base: "3rem", md: "7rem" }}
        />

        <Image
          src={Spurt}
          w={{ base: "6.5rem", md: "15rem" }}
          h={{ base: "3rem", md: "7rem" }}
        />
        <Image
          src={Firsti}
          w={{ base: "5rem", md: "10rem" }}
          h={{ base: "3rem", md: "7rem" }}
        />
      </Flex>
    </Box>
  );
};

export default CompaniesSection;
