import {
  Box,
  Container,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import calendarBackgroundImage from "../../images/eventcircles.png";
import { getEventsOnDate, getUpcomingEvents } from "../../service/eventsapi";
import { EventCard } from "../events/EventCard";
import "./Calender.css";

const CustomCalendar = () => {
  return (
    <Box
      bgImage={`url(${calendarBackgroundImage})`}
      bgSize="cover"
      bgPosition="center"
      color="black"
      paddingY={20}
      paddingX={5}
      borderRadius="md"
    >
      <Container maxW="container.lg" pt={5} mb={5}>
        <Box>
          <UpcomingEvents />
        </Box>
      </Container>
    </Box>
  );
};

export default CustomCalendar;

function UpcomingEvents() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch upcoming events
  const fetchUpcomingEvents = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const eventsResponse = await getUpcomingEvents();
      setUpcomingEvents(eventsResponse.data);
    } catch (error) {
      console.error("Error fetching upcoming events:", error);
      setError("Failed to fetch upcoming events.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingEvents(); // Fetch upcoming events when component mounts
  }, []);

  return (
    <div>
      <Box
        borderRadius="md"
        // w="80%"
        alignItems="center"
        justifyContent="center"
      >
        <Heading className="text-head" size="xl" color="#800020" mb={8}>
          Upcoming Events
        </Heading>
        {isLoading ? (
          <Spinner size="md" />
        ) : (
          error && <Text color="red.500">{error}</Text>
        )}
        <div>
          {upcomingEvents.length === 0 ? (
            <Text>No upcoming events</Text>
          ) : (
            <Box
              display="grid"
              boxSizing="border-box"
              gridTemplateColumns="repeat(auto-fill, minmax(min(100%, 250px), 1fr))"
              gap={6}
            >
              {upcomingEvents.map((event) => (
                <Link to={`/events/${event.documentId}`} key={event.documentId}>
                  <EventCard event={event} />
                </Link>
              ))}
            </Box>
          )}
        </div>
      </Box>
    </div>
  );
}

function Calendar() {
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(() => new Date());
  const [eventsOnDate, setEventsOnDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch events on the selected date
  const fetchEventsOnDate = async () => {
    setIsLoading(true);
    setError(null);
    const formattedDate = selectedDate.toISOString().split("T")[0];
    try {
      const eventsResponse = await getEventsOnDate(formattedDate);
      const events = eventsResponse.data;
      setEventsOnDate(events);
    } catch (error) {
      console.error("Error fetching events on date:", error);
      setError("Failed to fetch events.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEventsOnDate();
  }, [selectedDate]);

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay();
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const handleDateClick = (day) => {
    setSelectedDate(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day)
    );
  };

  const handlePrevMonth = () => {
    setSelectedDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() - 1,
        selectedDate.getDate()
      )
    );
  };

  const handleNextMonth = () => {
    setSelectedDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        selectedDate.getDate()
      )
    );
  };

  const daysInMonth = getDaysInMonth(
    selectedDate.getMonth(),
    selectedDate.getFullYear()
  );
  const firstDayOfMonth = getFirstDayOfMonth(
    selectedDate.getMonth(),
    selectedDate.getFullYear()
  );
  const days = Array.from({ length: daysInMonth }, (_, index) => index + 1);

  return (
    <Container maxW="container.sm" textAlign="center" p={0} m={0}>
      <Box boxShadow="lg" borderRadius="lg">
        <Table variant="unstyled" size="md" borderWidth="1px" rounded="lg">
          <Thead>
            <Tr>
              {["S", "M", "T", "W", "TH", "F", "S"].map((day) => (
                <Th key={day} textAlign="center" color="black" bgColor="white">
                  {day}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Array.from(
              { length: Math.ceil((daysInMonth + firstDayOfMonth) / 7) },
              (_, weekIndex) => (
                <Tr key={weekIndex}>
                  {Array.from({ length: 7 }, (_, dayIndex) => {
                    const dayNumber =
                      weekIndex * 7 + dayIndex + 1 - firstDayOfMonth;

                    // Check if this day has an event
                    const eventOnThisDay = events.find((event) => {
                      const eventDate = new Date(event.StartDate);
                      return (
                        eventDate.getDate() === dayNumber &&
                        eventDate.getMonth() === selectedDate.getMonth() &&
                        eventDate.getFullYear() === selectedDate.getFullYear()
                      );
                    });

                    return (
                      <Td
                        key={dayIndex}
                        textAlign="center"
                        bgColor={
                          dayNumber === selectedDate.getDate()
                            ? "#F2E5E9"
                            : eventOnThisDay
                            ? "#800020"
                            : "white"
                        }
                        border="1px"
                        borderColor="#E8E3E3"
                        onClick={() =>
                          dayNumber > 0 &&
                          dayNumber <= daysInMonth &&
                          handleDateClick(dayNumber)
                        }
                        cursor={
                          dayNumber > 0 && dayNumber <= daysInMonth
                            ? "pointer"
                            : "default"
                        }
                      >
                        {dayNumber > 0 && dayNumber <= daysInMonth
                          ? dayNumber
                          : ""}
                        {eventsOnDate &&
                          Array.isArray(eventsOnDate) &&
                          eventsOnDate
                            .filter((event) => {
                              const eventDate = new Date(event.StartDate);
                              return (
                                eventDate.getMonth() ===
                                  selectedDate.getMonth() &&
                                eventDate.getFullYear() ===
                                  selectedDate.getFullYear()
                              );
                            })
                            .map((filteredEvent) => (
                              <div key={filteredEvent.documentId}>
                                {isSameDay(
                                  new Date(filteredEvent.StartDate),
                                  new Date(
                                    selectedDate.getFullYear(),
                                    selectedDate.getMonth(),
                                    dayNumber
                                  )
                                ) && (
                                  <p
                                    style={{
                                      backgroundColor: "#99334D",
                                      color: "white",
                                      padding: "3px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {filteredEvent.Name}
                                  </p>
                                )}
                              </div>
                            ))}
                      </Td>
                    );
                  })}
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
}
