import { Box, Heading, Text } from "@chakra-ui/react";
import eventImage from "../../images/event.png"; // Placeholder image
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
// import { Prose } from "@nikolovlazar/chakra-ui-prose";

export function EventCard({ event }) {
  const startDate = new Date(event.StartDate);

  return (
    <Box
      boxShadow={"3px 6px 20px -3px rgba(0,0,0,0.33);"}
      overflow="hidden"
      rounded="xl"
      position="relative"
    >
      <img
        src={event.Image?.url ?? eventImage}
        alt="Event"
        style={{ width: "100%", height: "225px", objectFit: "cover" }}
      />
      <Box px={4} py={4} display="flex" gap={5}>
        <Box>
          <Text fontWeight="medium" color="gray.500" fontSize="sm" mb={1}>
            {new Intl.DateTimeFormat(undefined, { month: "short" }).format(
              startDate
            )}
          </Text>
          <Text fontWeight="bold" fontSize="xl">
            {startDate.getDate()}
          </Text>
        </Box>
        <Box flex={1}>
          <Heading as="h3" fontSize="lg" mb={2}>
            {event.Title}
          </Heading>
          <Box
            fontSize="sm"
            color="gray.500"
            style={{
              "---noLine": 3,
            }}
            className="line-clamp"
          >
            <BlocksRenderer content={[event.Description[0]]} />
          </Box>
        </Box>
      </Box>
      <Box
        position="absolute"
        top={4}
        left={4}
        px={4}
        py={2}
        bg="white"
        rounded="md"
        fontSize="sm"
        fontWeight="bold"
      >
        {!event.Price || event.Price === 0
          ? "Free"
          : new Intl.NumberFormat(undefined, {
              currency: event.Currency ?? "NGN",
              currencyDisplay: "symbol",
              style: "currency",
            }).format(event.Price)}
      </Box>
    </Box>
  );
}
