import React from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  Link,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  useMediaQuery,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
} from "@chakra-ui/react";
import backgroundImage from "../../images/homeheader.png";
// import bottomImage from "../../images/pinkUnion.png";
import bottomImage2 from "../../images/whiteline-svg.svg";
import Logo from "../../images/clearlogo.png";
import { ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { FaBars } from "react-icons/fa";

const EventsSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 769px)");

  return (
    <Box position="relative" style={{ fontFamily: "Avenir, sans-serif" }}>
      <Box
        position="relative"
        height={{ base: "768px", md: "800px", lg: "1000px" }}
        backgroundImage={`url(${backgroundImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        color="white"
        textAlign={{ base: "center", md: "left" }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          bg="#00000000"
          py={10}
          px={{ base: 10, md: 20 }}
          color="white"
          position="absolute"
          width="100%"
          zIndex="1"
        >
          <Link href="/">
            <Image
              src={Logo}
              alt="Innovate Africa Fund Logo"
              // boxSize={{ base: "50%", md: "15%" }}
              height={"60px"}
              objectFit="contain"
            />
          </Link>

          {isLargerThan768 ? (
            <Flex>
              <Link href="/" mr={4} fontSize={25}>
                Home
              </Link>
              <Link href="/about" mr={4} fontSize={25}>
                About Us
              </Link>
              <Link href="/events" mr={4} fontSize={25}>
                Events
              </Link>
              <Link href="/blog" mr={4} fontSize={25}>
                Blog
              </Link>
              <Link href="/contact" fontSize={25}>
                Contact Us
              </Link>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  marginLeft={3}
                  borderRadius={0}
                  bg="#FFF"
                  color="#800020"
                  p={5}
                  w={120}
                >
                  Join Us
                </MenuButton>
                <MenuList bg="#FFF" color="#800020">
                  <a
                    href="https://airtable.com/appC4SJsw04ifFzOk/shrJafumqJgDie7P4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MenuItem p={5}>Investors</MenuItem>
                  </a>
                  <a
                    href="https://airtable.com/appC4SJsw04ifFzOk/shrIofzEaUKZVqJ4n"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MenuItem p={5}>Operators</MenuItem>
                  </a>
                </MenuList>
              </Menu>
              <a
                href="https://airtable.com/appC4SJsw04ifFzOk/shrOchNT91hGSunOA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  bg="#FFFFFF"
                  color="#800020"
                  p={5}
                  width="100px"
                  rightIcon={<ChevronRightIcon />}
                  borderRadius={0}
                  ml={4}
                >
                  Apply
                </Button>
              </a>
            </Flex>
          ) : (
            <Button
              onClick={onOpen}
              color="#FEF7F9"
              width="40px"
              padding={0}
              borderRadius="md"
              bg="rgba(144, 33, 36, 0.1)"
            >
              <FaBars size={24} style={{ color: "#FEF7F9" }} />
            </Button>
          )}

          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Menu</DrawerHeader>
              <DrawerBody>
                <Flex direction="column">
                  <a href="/" onClick={onClose}>
                    Home
                  </a>
                  <a href="/about" onClick={onClose}>
                    About Us
                  </a>
                  <a href="/events" onClick={onClose}>
                    Events
                  </a>
                  <a href="/blog" onClick={onClose}>
                    Blog
                  </a>
                  <a href="/contact" onClick={onClose}>
                    Contact Us
                  </a>
                  <a
                    href="https://airtable.com/appC4SJsw04ifFzOk/shrOchNT91hGSunOA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      bg="#800020"
                      color="#FFFFFF"
                      p={5}
                      width="100px"
                      rightIcon={<ChevronRightIcon />}
                      borderRadius={0}
                    >
                      Apply
                    </Button>
                  </a>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      borderRadius={0}
                      color="#FFF"
                      bg="#800020"
                      p={5}
                      w={120}
                    >
                      Join Us
                    </MenuButton>
                    <MenuList bg="#FFF" color="#800020">
                      <a
                        href="https://airtable.com/appC4SJsw04ifFzOk/shrJafumqJgDie7P4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MenuItem p={5}>Investors</MenuItem>
                      </a>
                      <a
                        href="https://airtable.com/appC4SJsw04ifFzOk/shrIofzEaUKZVqJ4n"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MenuItem p={5}>Operators</MenuItem>
                      </a>
                    </MenuList>
                  </Menu>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
        <Box
          position="absolute"
          top={{ base: "50%", md: "44%" }}
          left={{ base: "0", md: "40px", lg: "35%" }}
          transform="translateY(-50%)"
          zIndex="1"
          padding={{ base: "20px", md: "40px", lg: "60px" }}
        >
          <Text
            fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
            fontWeight="semibold"
            mb={2}
          >
            We boldly back innovators committed to solving rather than merely
            exploiting problems. Our goal is to facilitate the infrastructure
            that helps founders unlock growth through audacious problem-solving.
            Don't let your vision fail – join our community of bold innovators
            today!
          </Text>
          <a
            href="https://airtable.com/appC4SJsw04ifFzOk/shrOchNT91hGSunOA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              bg="rgba(144, 33, 36, 0.1)"
              color="white"
              px={7}
              py={7}
              // minWidth="150px"
              width="unset"
              mt={5}
              fontSize="x-large"
              _hover={{
                color: "#800020",
                bg: "white",
              }}
              rightIcon={<ChevronRightIcon />}
            >
              Apply Here
            </Button>
          </a>
        </Box>
      </Box>

      {/* <Image
        src={bottomImage}
        alt="Bottom Image"
        w="100%"
        objectFit="cover"
        position="absolute"
        bottom="0"
        left="0"
      /> */}
      <Image
        src={bottomImage2}
        alt="Bottom Image"
        w="90%"
        mx="auto"
        objectFit="cover"
        position="absolute"
        bottom={{ base: "40px", md: "80px", lg: "140px" }}
        left="50%"
        transform="translate(-50%,0%) rotate(180deg)"
      />
    </Box>
  );
};

export default EventsSection;
