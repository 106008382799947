import React from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  Link,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import backgroundImage from "../../images/contact-us.jpg";
import bottomImage from "../../images/pinkUnion.png";
import Logo from "../../images/clearlogo.png";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { FaBars } from "react-icons/fa";

const EventsSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 769px)");

  return (
    <Box position="relative" style={{ fontFamily: "Avenir, sans-serif" }}>
      <Box
        position="relative"
        height={{ base: "430px", md: "430px", lg: "500px" }}
        backgroundImage={`url(${backgroundImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        color="white"
        textAlign={{ base: "center", md: "left" }}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="rgba(128, 0, 32, 0.7)"
        />

        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))"
        />

        <Flex
          justifyContent="space-between"
          alignItems="center"
          bg="#00000000"
          py={10}
          px={{ base: 10, md: 20 }}
          color="white"
          position="absolute"
          width="100%"
          zIndex="1"
        >
          <Link href="/">
            <Image
              src={Logo}
              alt="Innovate Africa Fund Logo"
              // boxSize={{ base: "50%", md: "15%" }}
              height={"60px"}
              objectFit="contain"
            />
          </Link>

          {isLargerThan768 ? (
            <Flex>
              <Link href="/" mr={4} fontSize={25}>
                Home
              </Link>
              <Link href="/about" mr={4} fontSize={25}>
                About Us
              </Link>
              <Link href="/events" mr={4} fontSize={25}>
                Events
              </Link>
              <Link href="/blog" mr={4} fontSize={25}>
                Blog
              </Link>
              <Link href="/contact" fontSize={25}>
                Contact Us
              </Link>
              <a
                href="https://airtable.com/appC4SJsw04ifFzOk/shrOchNT91hGSunOA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  bg="#FFFFFF"
                  color="#800020"
                  p={5}
                  width="100px"
                  rightIcon={<ChevronRightIcon />}
                  borderRadius={0}
                  ml={4}
                >
                  Apply
                </Button>
              </a>
            </Flex>
          ) : (
            <Button
              onClick={onOpen}
              color="#FEF7F9"
              width="40px"
              padding={0}
              borderRadius="md"
              bg="rgba(144, 33, 36, 0.1)"
            >
              <FaBars size={24} style={{ color: "#FEF7F9" }} />
            </Button>
          )}

          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Menu</DrawerHeader>
              <DrawerBody>
                <Flex direction="column">
                  <a href="/" onClick={onClose}>
                    Home
                  </a>
                  <a href="/about" onClick={onClose}>
                    About Us
                  </a>
                  <a href="/events" onClick={onClose}>
                    Events
                  </a>
                  <a href="/blog" onClick={onClose}>
                    Blog
                  </a>
                  <a href="/contact" onClick={onClose}>
                    Contact Us
                  </a>
                  <a
                    href="https://airtable.com/appC4SJsw04ifFzOk/shrOchNT91hGSunOA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      bg="#800020"
                      color="#FFFFFF"
                      p={5}
                      width="100px"
                      rightIcon={<ChevronRightIcon />}
                      borderRadius={0}
                    >
                      Apply
                    </Button>
                  </a>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
        <Box
          position="absolute"
          top="50%"
          left={{ base: "25%", md: "60%", lg: "60%" }}
          transform="translateY(-50%)"
          zIndex="1"
          padding={{ base: "20px", md: "40px", lg: "60px" }}
        >
          <Text
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
            fontWeight="bold"
            mb={2}
          >
            Contact Us
          </Text>
        </Box>
      </Box>

      <Image
        src={bottomImage}
        alt="Bottom Image"
        w="100%"
        //   h="100vh"
        objectFit="cover"
        position="absolute"
        bottom="0"
        left="0"
      />
    </Box>
  );
};

export default EventsSection;
